import * as React from 'react';
import { filter } from 'lodash';
import { createApplication, entityConfigs, Icons } from '@shapeable/core';
import { ApplicationLogo } from './components';
import { themes } from '@shapeable/theme';

const theme = themes.VILLARS_THEME;

// pre-patch configs to ensure that derived properties are correctly created 

export const application = createApplication({
  theme,
  entityConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
  },
  components: {
    Logo: ApplicationLogo,
  }
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.Trend;
delete entities.Topic;
delete entities.SubTopic;


delete entities.TrendExpertise;
delete entities.TopicExpertise;
delete entities.SubTopicExpertise;